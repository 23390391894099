import axiosInstance from './axiosInstance';

const getAuthHeader = () => {
  const token = localStorage.getItem('token');
  return token ? { Authorization: token } : {};
};

const api = {
  // Auth
//   login: (credentials) => axiosInstance.post('/login', credentials),

  // Trainings
//   getTrainings: () => axiosInstance.get('/trainings', { headers: getAuthHeader() }),
//   getTraining: (id) => axiosInstance.get(`/trainings/${id}`, { headers: getAuthHeader() }),
//   createTraining: (training) => axiosInstance.post('/trainings', training, { headers: getAuthHeader() }),
//   updateTraining: (id, training) => axiosInstance.put(`/trainings/${id}`, training, { headers: getAuthHeader() }),
//   deleteTraining: (id) => axiosInstance.delete(`/trainings/${id}`, { headers: getAuthHeader() }),

  // Exercises
//   getExercises: () => axiosInstance.get('/exercises', { headers: getAuthHeader() }),
    getExerciseOptions: () => axiosInstance.get('/exercises', { headers: getAuthHeader() }),

  // Add more API calls as needed
};

export default api;