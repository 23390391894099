import React from 'react';
import { Typography, Button, Box, Paper, List, ListItem, ListItemText } from '@mui/material';

function ViewTraining({training, startThisExercise}) {
  if (!training) return <Typography>Loading...</Typography>;

  const getCurrentIndices = () => {
    for (let i = 0; i < training.exercises.length; i++) {
      for (let j = 0; j < training.exercises[i].sets.length; j++) {
        if (!training.exercises[i].sets[j].finish) {
          return { exerciseIndex: i, setIndex: j };
        }
      }
    }
    return { exerciseIndex: -1, setIndex: -1 }; // All sets finished
  };


  

  const { exerciseIndex: currentExerciseIndex, setIndex: currentSetIndex } = getCurrentIndices();

  const formatTime = (timeString, fullDate = false) => {
    if (!timeString) return 'Not started';
    const date = new Date(timeString);
    return fullDate ? date.toLocaleString() : date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false });
  };

  const prepareSetListItem = (set, setIndex) => {
    let style = {backgroundColor: 'inherit', color: 'inherit', finishedText: 'Completed', icon: ''};
    if (set.finish) {
        if (set.deviation === 'skipped') {
            style.backgroundColor = 'rgba(255, 0, 0, 0.1)';
            style.finishedText = 'Skipped';
            style.icon = '❌ ';
        } else if (set.deviation === 'added') {
            style.backgroundColor = 'rgba(0, 255, 0, 0.2)';
            style.finishedText = 'Added';
            style.icon = '➕ ';
        } else {
            style.backgroundColor = 'inherit';
            style.finishedText = 'Completed';
            style.icon = '✅ ';
        }
    }
    return (<ListItemText
        primary={`${style.icon}Set ${setIndex + 1}: ${set.reps} reps x ${set.weight}kg`}
        secondary={`${style.finishedText}: ${formatTime(set.finish)}`}
        sx={{ backgroundColor: `${style.backgroundColor}` }}
      />)
  }

  return (
    <Box>
      <Typography>Started: {formatTime(training.start, true)}</Typography>
      <List>
        {training.exercises.map((exercise, exerciseIndex) => (
          <Paper 
            key={exercise.id || `exercise-${exerciseIndex}-${exercise.name}`}
            elevation={3} 
            sx={{ 
              my: 2, 
              p: 2, 
              backgroundColor: exerciseIndex === currentExerciseIndex ? '#e3f2fd' : 'inherit'
            }}
          >
            <Typography variant="h6">{exercise.name}</Typography>
            {currentSetIndex === 0 && 
             exercise.sets.every(set => !set.finish) && 
             exerciseIndex !== currentExerciseIndex && (
              <Button color="success" onClick={() => startThisExercise(exerciseIndex)}>
                Start this exercise
              </Button>
            )}

            <List>
              {exercise.sets.map((set, setIndex) => (
                <ListItem 
                  key={`set-${exerciseIndex}-${setIndex}`}
                  sx={{
                    backgroundColor: exerciseIndex === currentExerciseIndex && setIndex === currentSetIndex ? '#bbdefb' : 'inherit'
                  }}
                >
                  {prepareSetListItem(set, setIndex)}
                </ListItem>
              ))}
            </List>
          </Paper>
        ))}
      </List>
    </Box>
  );
}

export default ViewTraining;