import React, { useEffect, useState } from 'react';
import {
    TextField, Button, Stack, Autocomplete,
    InputAdornment,
    IconButton
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';

const ExerciseForm = ({
    exercise,
    exerciseOptions,
    updateExercise,
    setExerciseError,
}) => {
    const [error, setError] = useState(false);
    const [exerciseName, setExerciseName] = useState(exercise.name);

    useEffect(() => {
        const e = !exercise.name ? 'Exercise name is required' : '';
        setError(e);
        setExerciseError(!!e);
    }, [exercise.name]);



    const addSet = () => {
        const updatedExercise = { ...exercise, sets: [...exercise.sets, { reps: 1, weight: 0 }] };
        updateExercise(updatedExercise);
    };


    const updateSet = (setIndex, field, value) => {
        const updatedExercise = { ...exercise, sets: [...exercise.sets] };
        updatedExercise.sets[setIndex][field] = value;
        updateExercise(updatedExercise);
    };

    const renderClearButton = (onClear) => (
        <InputAdornment position="end">
            <IconButton onClick={onClear} edge="end">
                <ClearIcon />
            </IconButton>
        </InputAdornment>
    );



    return (
        <Stack spacing={2}>
            <Autocomplete
                freeSolo
                options={exerciseOptions}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Exercise Name"
                        error={!!error}
                        helperText={error}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {renderClearButton(() => {
                                        updateExercise({ ...exercise, name: '' });
                                    })}
                                </>
                            ),
                        }}
                    />
                )}
                value={exercise.name}
                onInputChange={(e, newValue) => {
                    const newExercise = { ...exercise, name: newValue };
                    updateExercise(newExercise);
                }}
                fullWidth
            />
            {exercise.sets.map((set, setIndex) => (
                <Stack key={setIndex} direction="row" spacing={2} sx={{ width: '100%' }}>
                    <TextField
                        type="number"
                        label="Reps"
                        value={set.reps}
                        onChange={(e) => updateSet(setIndex, 'reps', e.target.value)}
                        InputProps={{
                            endAdornment: renderClearButton(() => {
                                updateSet(setIndex, 'reps', "");
                            })
                        }}
                        fullWidth
                    />
                    <TextField
                        type="number"
                        label="Weight"
                        value={set.weight}
                        onChange={(e) => updateSet(setIndex, 'weight', e.target.value)}
                        InputProps={{
                            endAdornment: renderClearButton(() => {
                                updateSet(setIndex, 'weight', 0);
                            })
                        }}
                        fullWidth
                    />
                </Stack>
            ))}
            <Button
                onClick={() => addSet()}
                startIcon={<AddIcon />}
                variant="outlined"
                fullWidth
            >
                Add Set
            </Button>
        </Stack>
    );
};

export default ExerciseForm;