import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import TrainingsList from './components/TrainingsList';
import NewTraining from './components/NewTraining';
import EditTraining from './components/EditTraining';
import PerformTraining from './components/PerformTraining';
import Login from './components/Login';
import axiosInstance from './axiosInstance';
import config from './config';
import emitter from './eventEmitter'; // Import the event emitter
import { Snackbar, Alert } from '@mui/material';

const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem('token');
  if (!token) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

// ErrorListener Component
const ErrorListener = () => {
  const navigate = useNavigate();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'error',
  });

  useEffect(() => {
    // Handler for showing Snackbar
    const handleShowSnackbar = ({ message, severity }) => {
      setSnackbar({ open: true, message, severity });
    };

    // Handler for navigation
    const handleNavigate = (path) => {
      navigate(path, { replace: true });
    };

    // Subscribe to events
    emitter.on('showSnackbar', handleShowSnackbar);
    emitter.on('navigate', handleNavigate);

    // Clean up subscriptions on unmount
    return () => {
      emitter.off('showSnackbar', handleShowSnackbar);
      emitter.off('navigate', handleNavigate);
    };
  }, [navigate]);

  // Handler to close Snackbar
  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  return (
    <Snackbar
      open={snackbar.open}
      autoHideDuration={6000}
      onClose={handleCloseSnackbar}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
        {snackbar.message}
      </Alert>
    </Snackbar>
  );
};

function App() {
  const handleUpdateTraining = async (training) => {
    const token = localStorage.getItem('token');
    try {
      await axiosInstance.put(`/trainings/${training._id}`, training, {
        headers: { 'Authorization': token }
      });
      console.log('Training updated:', training);
    } catch (error) {
      // Error handling is managed by Axios interceptor
    }
  };

  return (
    <Router>
      {/* Place ErrorListener inside Router to use useNavigate */}
      <ErrorListener />
      <SnackbarProvider>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<ProtectedRoute><TrainingsList /></ProtectedRoute>} />
        <Route path="/edit-training/:id" element={<ProtectedRoute><EditTraining /></ProtectedRoute>} />
        <Route path="/perform-training/:id" element={<ProtectedRoute><PerformTraining onUpdate={handleUpdateTraining} /></ProtectedRoute>} />
        <Route path="/new-training" element={<ProtectedRoute><NewTraining /></ProtectedRoute>} />
        <Route path="/403" element={<div>403 Forbidden</div>} />
      </Routes>
      </SnackbarProvider>
    </Router>
  );
}

export default App;