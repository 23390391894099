const loadAudio = (url) => {
  return new Promise((resolve, reject) => {
    const audio = new Audio(url);
    audio.addEventListener('canplaythrough', () => resolve(audio));
    audio.addEventListener('error', reject);
    audio.load();
  });
};

let bellSound = null;

const loadBellSound = async () => {
  if (!bellSound) {
    try {
      bellSound = await loadAudio(`${process.env.PUBLIC_URL}/sounds/bell.mp3`);
    } catch (error) {
      console.error('Failed to load bell sound:', error);
    }
  }
  return bellSound;
};

export { loadBellSound };