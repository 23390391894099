import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

const Timer = React.memo(({ initialRestTime = 60, onTimerUpdate, bellSound, lastFinishTime }) => {
  const [timerDisplay, setTimerDisplay] = useState('00:00.000');
  const [timerColor, setTimerColor] = useState('green');
  const timerRef = useRef(0);
  const lastUpdateTimeRef = useRef(0);
  const restTimeRef = useRef(initialRestTime);
  const requestRef = useRef();
  const startTimeRef = useRef();
  const hasPlayedSoundRef = useRef(false);
  const { enqueueSnackbar } = useSnackbar();

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60000);
    const seconds = Math.floor((time % 60000) / 1000);
    const milliseconds = time % 1000;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}.${milliseconds.toFixed(0).toString().padStart(3, '0')}`;
  };

  const updateTimerColor = useCallback((elapsedTime) => {
    const elapsedSeconds = elapsedTime / 1000;
    let newColor;
    if (elapsedSeconds < restTimeRef.current * 0.75) {
      newColor = 'green';
    } else if (elapsedSeconds < restTimeRef.current) {
      newColor = 'orange';
    } else {
      newColor = 'red';
      if (!hasPlayedSoundRef.current) {
        bellSound.play().catch(error => enqueueSnackbar('Error playing notification sound', { variant: 'error' }));
        hasPlayedSoundRef.current = true;
      }
    }
    if (newColor !== timerColor) {
      setTimerColor(newColor);
    }
  }, [timerColor, bellSound]);

  const animate = useCallback((time) => {
    if (startTimeRef.current === undefined) {
      startTimeRef.current = time;
    }
    const elapsedTime = time - startTimeRef.current;
    timerRef.current = elapsedTime;

    if (time - lastUpdateTimeRef.current > 100) { // Update every 100ms
      setTimerDisplay(formatTime(elapsedTime));
      updateTimerColor(elapsedTime);
      lastUpdateTimeRef.current = time;
      onTimerUpdate(elapsedTime);
    }

    requestRef.current = requestAnimationFrame(animate);
  }, [updateTimerColor, onTimerUpdate]);


  useEffect(() => {
    hasPlayedSoundRef.current = false;
    
    if (lastFinishTime) {
      const initialElapsedTime = Date.now() - new Date(lastFinishTime).getTime();
      startTimeRef.current = performance.now() - initialElapsedTime;
      
      // Set initial color and check if sound should have played
      const initialElapsedSeconds = initialElapsedTime / 1000;
      if (initialElapsedSeconds >= restTimeRef.current) {
        setTimerColor('red');
        hasPlayedSoundRef.current = true;
      } else if (initialElapsedSeconds >= restTimeRef.current * 0.75) {
        setTimerColor('orange');
      }
    }

    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
  }, [animate, lastFinishTime]);

  return (
    <Typography variant="h6" style={{ color: timerColor }}>
      Timer: {timerDisplay}
    </Typography>
  );
});

export default Timer;