import React from 'react';
import { useLocation } from 'react-router-dom';
import TrainingForm from './TrainingForm';

const NewTraining = () => {
  const location = useLocation();
  const copiedTraining = location.state?.copiedTraining;

  return <TrainingForm initialTraining={copiedTraining} isEditing={false} />;
};

export default NewTraining;