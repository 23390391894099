import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Button, Typography, Box, TextField, Snackbar, List, ListItem, Paper, ListItemText, Stack, Card, CardContent, CardActions } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import ViewTraining from './ViewTraining';
import Timer from './Timer';
import { loadBellSound } from '../audioResources';
import { useSnackbar } from 'notistack';
import ExerciseForm from './ExerciseForm';
import api from '../api';

function PerformTraining({ onUpdate }) {
    const { id } = useParams();
    const navigate = useNavigate();
    const [training, setTraining] = useState(null);
    const [currentExerciseIndex, setCurrentExerciseIndex] = useState(0);
    const [currentSetIndex, setCurrentSetIndex] = useState(0);
    const [finishedTraining, setFinishedTraining] = useState(false);
    const [editedWeight, setEditedWeight] = useState('');
    const [editedReps, setEditedReps] = useState('');
    const [restTime, setRestTime] = useState(60);
    const [timerKey, setTimerKey] = useState(0);
    const [bellSound, setBellSound] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const [addExerciseWidget, setAddExerciseWidget] = useState(null);

    const [exerciseOptions, setExerciseOptions] = useState([]);
    const [exerciseToAdd, setExerciseToAdd] = useState({ name: '', sets: [{ reps: 1, weight: 0 }] });
    const [exerciseToAddError, setExerciseToAddError] = useState({});


    const fetchExerciseOptions = async () => {
        try {
          const response = await api.getExerciseOptions();
          setExerciseOptions(response.data);
        } catch (error) {
          enqueueSnackbar('Error fetching exercise options', { variant: 'error' });
        }
      };
    
    useEffect(() => {
        loadBellSound().then(sound => setBellSound(sound));
    }, []);

    useEffect(() => {
        fetchExerciseOptions();
    }, [training]);

    const findFirstUnfinishedSet = useCallback((training) => {
        for (let i = 0; i < training.exercises.length; i++) {
            for (let j = 0; j < training.exercises[i].sets.length; j++) {
                if (!training.exercises[i].sets[j].finish) {
                    return { exerciseIndex: i, setIndex: j };
                }
            }
        }
        return null;
    }, []);

    useEffect(() => {
        const fetchTraining = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${config.API_URL}/api/trainings/${id}`, {
                    headers: { 'Authorization': token }
                });
                const fetchedTraining = response.data;
                setTraining(fetchedTraining);
                const firstUninishedExercise = findFirstUnfinishedSet(fetchedTraining);
                if (firstUninishedExercise) {
                    setCurrentExerciseIndex(firstUninishedExercise.exerciseIndex);
                    setCurrentSetIndex(firstUninishedExercise.setIndex);
                } else {
                    setCurrentExerciseIndex(fetchedTraining.exercises.length);
                    setCurrentSetIndex(0);
                    setFinishedTraining(true);
                }
            } catch (error) {
                console.error('Error fetching training:', error);
            }
        };

        fetchTraining();
        console.log('fetching training');
    }, [id]);
    // }, [id, findFirstUnfinishedSet]);

    const currentExercise = useMemo(() => training?.exercises[currentExerciseIndex], [training, currentExerciseIndex]);
    const currentSet = useMemo(() => currentExercise?.sets[currentSetIndex], [currentExercise, currentSetIndex]);

    useEffect(() => {
        if (currentSet) {
            setEditedWeight(currentSet.weight.toString());
            setEditedReps(currentSet.reps.toString());
        }
    }, [currentSet]);

    const whatIsNext = (currentExerciseIndex, currentSetIndex) => {
        if (currentSetIndex < training.exercises[currentExerciseIndex].sets.length - 1) {
            setCurrentSetIndex(currentSetIndex + 1);
        } else if (currentExerciseIndex < training.exercises.length - 1) {
            setCurrentExerciseIndex(currentExerciseIndex + 1);
            setCurrentSetIndex(0);
        } else {
            setCurrentExerciseIndex(training.exercises.length);
            setCurrentSetIndex(0);
            setFinishedTraining(true);
        }
    }



    const handleFinishSet = useCallback(() => {
        const updatedTraining = { ...training };
        const updatedSet = {
            ...updatedTraining.exercises[currentExerciseIndex].sets[currentSetIndex],
            weight: parseFloat(editedWeight),
            reps: parseInt(editedReps, 10),
            finish: new Date().toISOString()
        };
        updatedTraining.exercises[currentExerciseIndex].sets[currentSetIndex] = updatedSet;

        updateTraining(updatedTraining);

        whatIsNext(currentExerciseIndex, currentSetIndex);

        setTimerKey(prevKey => prevKey + 1);  // Reset timer
    }, [training, currentExerciseIndex, currentSetIndex, editedWeight, editedReps]);

    const handleSkipSet = useCallback(() => {
        const updatedTraining = { ...training };
        const oldSet = updatedTraining.exercises[currentExerciseIndex].sets[currentSetIndex];
        if (oldSet.deviation === 'added') {
            updatedTraining.exercises[currentExerciseIndex].sets.splice(currentSetIndex, 1);
        } else {
            const updatedSet = {
                ...oldSet,
                deviation: 'skipped',
                finish: new Date().toISOString()
            };
            updatedTraining.exercises[currentExerciseIndex].sets[currentSetIndex] = updatedSet;
        }

        updateTraining(updatedTraining);

        whatIsNext(currentExerciseIndex, currentSetIndex);

        setTimerKey(prevKey => prevKey + 1);  // Reset timer
    }, [training, currentExerciseIndex, currentSetIndex]);

    const handleAddSet = useCallback(() => {
        const updatedTraining = { ...training };

        const addedSet = { ...updatedTraining.exercises[currentExerciseIndex].sets[currentSetIndex], deviation: 'added' };
        addedSet.weight = parseFloat(editedWeight);
        addedSet.reps = parseInt(editedReps, 10);
        updatedTraining.exercises[currentExerciseIndex].sets.push(addedSet);

        setTraining(updatedTraining);

        handleFinishSet();

    }, [training, currentExerciseIndex, currentSetIndex, editedWeight, editedReps]);

    const startThisExercise = async (exerciseIndex) => {
        const updatedTraining = { ...training };
        // Find the exercise at the given exerciseIndex
        const exerciseToMove = updatedTraining.exercises[exerciseIndex];

        // Remove the exercise from its current position
        updatedTraining.exercises.splice(exerciseIndex, 1);

        // Insert the exercise before the current exercise
        updatedTraining.exercises.splice(currentExerciseIndex, 0, exerciseToMove);

        updateTraining(updatedTraining);
    };


    const updateTraining = async (updatedTraining) => {
        onUpdate(updatedTraining);
        setTraining(updatedTraining);
    }

    const handleTimerUpdate = useCallback((elapsedTime) => {
        // Handle timer updates if needed
    }, []);

    const getLastFinishTime = useCallback(() => {
        if (!training) return null;
        if (!currentExercise) return training.start;
        const lastFinishedSet = training.exercises.map(exercise => exercise.sets).flat().filter(set => set.finish).sort((a, b) => new Date(b.finish) - new Date(a.finish))[0];
        return lastFinishedSet ? lastFinishedSet.finish : training.start;
    }, [training, currentExercise, currentSetIndex]);


    const formatTime = (timeString, fullDate = false) => {
        if (!timeString) return 'Not started';
        const date = new Date(timeString);
        return fullDate ? date.toLocaleString() : date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false });
    };

    const prepareSetListItem = (set, setIndex) => {
        let style = { backgroundColor: 'inherit', color: 'inherit', finishedText: 'Completed', icon: '' };
        if (set.finish) {
            if (set.deviation === 'skipped') {
                style.backgroundColor = 'rgba(255, 0, 0, 0.1)';
                style.finishedText = 'Skipped';
                style.icon = '❌ ';
            } else if (set.deviation === 'added') {
                style.backgroundColor = 'rgba(0, 255, 0, 0.2)';
                style.finishedText = 'Added';
                style.icon = '➕ ';
            } else {
                style.backgroundColor = 'inherit';
                style.finishedText = 'Completed';
                style.icon = '✅ ';
            }
        }
        return (<ListItemText
            primary={`${style.icon}Set ${setIndex + 1}: ${set.reps} reps x ${set.weight}kg`}
            secondary={set.finish ? `${style.finishedText}: ${formatTime(set.finish)}` : 'not finished'}
            sx={{ backgroundColor: `${style.backgroundColor}` }}
        />)
    }

    const handleAddExercise = (exercise, exerciseIndex) => {
        const updatedTraining = { ...training };
        updatedTraining.exercises.splice(exerciseIndex, 0, exercise);
        updateTraining(updatedTraining);
        setAddExerciseWidget(null);
        setExerciseToAdd({ name: '', sets: [{ reps: 1, weight: 0 }] });
        setFinishedTraining(false);
    }

    if (!training) return <div>Loading...</div>;

    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <Button variant="contained" onClick={() => navigate('/')} startIcon={<ArrowBackIcon />}>
                    Trainings List
                </Button>
                <Button variant="contained" onClick={() => navigate(`/edit-training/${id}`)} startIcon={<EditIcon />}>
                    Edit Training
                </Button>
            </Box>

            <Typography variant="h4">{training.name}</Typography>

            {!finishedTraining ? (
                <>
                    {!training.start && (
                        <Button color="success" onClick={() => updateTraining({ ...training, start: new Date().toISOString() })}>
                            Perform
                        </Button>
                    )}

                </>
            ) : (
                <Box>
                    <Typography variant="h5">Congratulations!</Typography>
                    <Typography variant="h6">You have finished the training.</Typography>
                </Box>
            )}

            <Box>
                <Typography>Started: {formatTime(training.start, true)}</Typography>
                <List>
                    {training.exercises.map((exercise, exerciseIndex) => ([
                            <Paper
                                key={exercise.id || `exercise-${exerciseIndex}-${exercise.name}`}
                                elevation={3}
                                sx={{
                                    my: 2,
                                    p: 2,
                                    backgroundColor: (!finishedTraining && exerciseIndex === currentExerciseIndex) ? '#e3f2fd' : 'inherit'
                                }}
                            >
                                <Typography variant="h6">{exercise.name}</Typography>
                                {currentSetIndex === 0 &&
                                    exercise.sets.every(set => !set.finish) &&
                                    exerciseIndex !== currentExerciseIndex && (
                                        <Button color="success" onClick={() => startThisExercise(exerciseIndex)}>
                                            Start this exercise
                                        </Button>
                                    )}

                                <List>
                                    {exercise.sets.map((set, setIndex) => (
                                        <ListItem
                                            key={`set-${exerciseIndex}-${setIndex}`}
                                            sx={{
                                                backgroundColor: (!finishedTraining && exerciseIndex === currentExerciseIndex) && setIndex === currentSetIndex ? '#ffffff' : 'inherit'
                                            }}
                                        >
                                            <Stack>
                                                {prepareSetListItem(set, setIndex)}
                                                {(training.start && !finishedTraining && exerciseIndex == currentExerciseIndex) && (setIndex == currentSetIndex) && (
                                                    <Stack>
                                                        <Timer
                                                            key={timerKey}
                                                            initialRestTime={restTime}
                                                            onTimerUpdate={handleTimerUpdate}
                                                            bellSound={bellSound}
                                                            lastFinishTime={getLastFinishTime()}
                                                        />
                                                        <Box>
                                                            <TextField
                                                                label="Reps"
                                                                type="number"
                                                                value={editedReps}
                                                                onChange={(e) => setEditedReps(e.target.value)}
                                                                margin="normal"
                                                            />
                                                            <TextField
                                                                label="Weight (kg)"
                                                                type="number"
                                                                value={editedWeight}
                                                                onChange={(e) => setEditedWeight(e.target.value)}
                                                                margin="normal"
                                                            />
                                                        </Box>
                                                        <Box>
                                                            <Button color="success" onClick={handleFinishSet}>
                                                                Success
                                                            </Button>
                                                            <Button color="error" onClick={handleSkipSet}>
                                                                Skip
                                                            </Button>
                                                            {currentSetIndex === currentExercise.sets.length - 1 && (
                                                                <Button color="success" onClick={handleAddSet}>
                                                                    Want more
                                                                </Button>
                                                            )}
                                                        </Box>
                                                    </Stack>

                                                )}
                                            </Stack>
                                        </ListItem>
                                    ))}
                                </List>
                            </Paper>
                            ,
                            ((currentExerciseIndex > exerciseIndex) && (exerciseIndex !== training.exercises.length - 1)) ? null : ((addExerciseWidget != exerciseIndex) ? (
                                <Button
                                    onClick={() => setAddExerciseWidget(exerciseIndex)}
                                    startIcon={<AddIcon />}
                                    variant="outlined"
                                    sx={{ mt: 2, mb: 2 }}
                                    key={`addExerciseButton-${exerciseIndex}`}
                                    fullWidth
                                >
                                    Add Exercise
                                </Button>
                            ) : (
                                <Card key={`add-exercise-${exerciseIndex}`} variant="outlined">
                                    <CardContent>
                                        <ExerciseForm
                                            exercise={exerciseToAdd}
                                            exerciseOptions={exerciseOptions}
                                            updateExercise={setExerciseToAdd}
                                            setExerciseError={(error) => setExerciseToAddError(error)}
                                        />
                                    </CardContent>
                                    <CardActions sx={{ justifyContent: 'space-between' }}>
                                        <Box>
                                            <Button
                                                onClick={() => handleAddExercise(exerciseToAdd, exerciseIndex+1)}
                                                disabled={!!exerciseToAddError}
                                                variant="contained"
                                            >
                                                Add Exercise
                                            </Button>
                                            <Button
                                                onClick={() => setAddExerciseWidget(null)}
                                                disabled={false}
                                            >
                                                Cancel
                                            </Button>
                                        </Box>
                                    </CardActions>
                                </Card>
                            ))


                    ])).flat()}
                </List>
            </Box>
        </Box>
    );
}

export default React.memo(PerformTraining);