import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import TrainingForm from './TrainingForm';
import config from '../config';
import { Box, Button } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

function EditTraining() {
  const [initialTraining, setInitialTraining] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  const handlePerformTraining = async (trainingId) => {
    navigate(`/perform-training/${trainingId}`);
  };

  useEffect(() => {
    const fetchTraining = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${config.API_URL}/api/trainings/${id}`, {
          headers: { 'Authorization': token }
        });
        setInitialTraining(response.data);
      } catch (error) {
        console.error('Error fetching training:', error);
      }
    };

    fetchTraining();
  }, [id]);

  if (!initialTraining) return <div>Loading...</div>;

  return (
    <div>
      <Box display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          color="success"
          onClick={() => handlePerformTraining(initialTraining._id)}
          startIcon={<PlayArrowIcon />}
        >
          Perform Training
        </Button>
      </Box>
      <TrainingForm initialTraining={initialTraining} isEditing={true} />
    </div>
  );
}

export default EditTraining;
