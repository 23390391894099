const configs = {
    development: {
      API_URL: 'http://localhost:5005',
    },
    production: {
      API_URL: 'https://api.gg.mrlxhg.com', // Replace with your actual production API URL
    },
  };

  const env = process.env.REACT_APP_ENV || 'development';

  export default configs[env];