import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, Snackbar, IconButton, Stack, InputAdornment, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Card, CardContent, CardActions } from '@mui/material';
import { Autocomplete } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../axiosInstance';
import ExerciseForm from './ExerciseForm';
import api from '../api';
import { useSnackbar } from 'notistack';


const TrainingForm = ({ initialTraining, isEditing }) => {
  const [training, setTraining] = useState(initialTraining || {
    name: '',
    date: new Date().toISOString().split('T')[0],
    exercises: []
  });
  const [exerciseOptions, setExerciseOptions] = useState([]);
  const [errors, setErrors] = useState({
    name: '',
    exercises: []
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const navigate = useNavigate();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [exerciseToDelete, setExerciseToDelete] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    fetchExerciseOptions();
    if (initialTraining && initialTraining.date) {
      setTraining(prev => ({ ...prev, date: formatDateForInput(initialTraining.date) }));
    }
  }, [initialTraining]);

  const fetchExerciseOptions = async () => {
    try {
      const response = await api.getExerciseOptions();
      setExerciseOptions(response.data);
    } catch (error) {
      enqueueSnackbar('Error fetching exercise options', { variant: 'error' });
    }
  };

  const formatDateForInput = (dateString) => {
    console.log('Formatting date:', dateString);

    // If it's a valid date string (including ISO 8601 format), convert it
    if (!isNaN(Date.parse(dateString))) {
      return new Date(dateString).toISOString().split('T')[0];
    }

    // Check if the date is already in YYYY-MM-DD format
    if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
      return dateString;
    }

    // Check if the date is in DD.MM.YYYY format
    const parts = dateString.split('.');
    if (parts.length === 3) {
      const [day, month, year] = parts;
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    }

    // If the format is unrecognized, return the current date
    console.error('Unrecognized date format:', dateString);
    return new Date().toISOString().split('T')[0];
  };

  const validateForm = () => {
    let tempErrors = {
      name: training.name ? "" : "Training name is required",
      exercises: training.exercises.map(exercise => exercise.name ? "" : "Exercise name is required")
    };
    setErrors(tempErrors);
    return !tempErrors.name && tempErrors.exercises.every(error => !error);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const cleanedTraining = {
      ...training,
      exercises: training.exercises.map(exercise => ({
        ...exercise,
        sets: exercise.sets.filter(set => set.reps !== 0 || set.weight !== 0)
      }))
    };

    if (validateForm()) {
      try {
        const token = localStorage.getItem('token');
        if (isEditing) {
          await axiosInstance.put(`/trainings/${training._id}`, cleanedTraining, {
            headers: { 'Authorization': token }
          });
          setSnackbar({ open: true, message: 'Training updated successfully', severity: 'success' });
        } else {
          await axiosInstance.post('/trainings', cleanedTraining, {
            headers: { 'Authorization': token }
          });
          setSnackbar({ open: true, message: 'Training created successfully', severity: 'success' });
        }
        navigate('/');
      } catch (error) {
        console.error('Error saving training:', error);
        setSnackbar({ open: true, message: 'Error saving training', severity: 'error' });
      }
    }
  };

  const addExercise = () => {
    setTraining({
      ...training,
      exercises: [...training.exercises, { name: '', sets: [{ reps: 0, weight: 0 }] }]
    });
    setErrors({
      ...errors,
      exercises: [...errors.exercises, ""]
    });
  };

  const updateExercise = (exerciseIndex, value) => {
    const updatedExercises = [...training.exercises];
    updatedExercises[exerciseIndex] = value;
    setTraining({ ...training, exercises: updatedExercises });

  };

  const setExerciseError = (exerciseIndex, error) => {
    const updatedErrors = [...errors.exercises];
    updatedErrors[exerciseIndex] = error;
    setErrors({ ...errors, exercises: updatedErrors });
  };

  const moveExercise = (index, direction) => {
    const newExercises = [...training.exercises];
    if (direction === 'up' && index > 0) {
      [newExercises[index - 1], newExercises[index]] = [newExercises[index], newExercises[index - 1]];
    } else if (direction === 'down' && index < newExercises.length - 1) {
      [newExercises[index], newExercises[index + 1]] = [newExercises[index + 1], newExercises[index]];
    }
    setTraining({ ...training, exercises: newExercises });
  };

  const renderClearButton = (onClear) => (
    <InputAdornment position="end">
      <IconButton onClick={onClear} edge="end">
        <ClearIcon />
      </IconButton>
    </InputAdornment>
  );

  const handleDeleteExercise = (index) => {
    setExerciseToDelete(index);
    setDeleteDialogOpen(true);
  };

  const confirmDeleteExercise = () => {
    const newExercises = training.exercises.filter((_, index) => index !== exerciseToDelete);
    setTraining({ ...training, exercises: newExercises });
    setErrors({
      ...errors,
      exercises: errors.exercises.filter((_, index) => index !== exerciseToDelete)
    });
    setDeleteDialogOpen(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="h4" gutterBottom>{isEditing ? 'Edit Training' : 'New Training'}</Typography>
      <TextField
        label="Training Name"
        value={training.name}
        onChange={(e) => {
          setTraining({ ...training, name: e.target.value });
          setErrors({ ...errors, name: e.target.value ? "" : "Training name is required" });
        }}
        error={!!errors.name}
        helperText={errors.name}
        fullWidth
        margin="normal"
        InputProps={{
          endAdornment: renderClearButton(() => {
            setTraining({ ...training, name: '' });
            setErrors({ ...errors, name: "Training name is required" });
          })
        }}
      />
      <TextField
        type="date"
        value={training.date}
        onChange={(e) => setTraining({ ...training, date: e.target.value })}
        fullWidth
        margin="normal"
      />
      <Stack spacing={2} mt={2}>
        {training.exercises.map((exercise, exerciseIndex) => (
          <Card key={exerciseIndex} variant="outlined">
            <CardContent>
              <ExerciseForm
                exercise={exercise}
                exerciseOptions={exerciseOptions}
                updateExercise={(exercise) => updateExercise(exerciseIndex, exercise)}
                setExerciseError={(error) => setExerciseError(exerciseIndex, error)}
              />
            </CardContent>
            <CardActions sx={{ justifyContent: 'space-between' }}>
              <Box>
                <IconButton
                  onClick={() => moveExercise(exerciseIndex, 'up')}
                  disabled={exerciseIndex === 0}
                >
                  <ArrowUpwardIcon />
                </IconButton>
                <IconButton
                  onClick={() => moveExercise(exerciseIndex, 'down')}
                  disabled={exerciseIndex === training.exercises.length - 1}
                >
                  <ArrowDownwardIcon />
                </IconButton>
              </Box>
              <IconButton
                onClick={() => handleDeleteExercise(exerciseIndex)}
                color="error"
              >
                <DeleteIcon />
              </IconButton>
            </CardActions>
          </Card>
        ))}
      </Stack>
      <Button
        onClick={addExercise}
        startIcon={<AddIcon />}
        variant="contained"
        sx={{ mt: 2, mb: 2 }}
        fullWidth
      >
        Add Exercise
      </Button>
      <Box mt={2} display="flex" justifyContent="space-between">
        <Button onClick={() => navigate('/')} variant="outlined">
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={!training.name || training.exercises.some(ex => !ex.name)}
        >
          {isEditing ? 'Update Training' : 'Save Training'}
        </Button>
      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        message={snackbar.message}
      />
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Exercise?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this exercise? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDeleteExercise} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
};

export default TrainingForm;