// src/axiosInstance.js
import axios from 'axios';
import emitter from './eventEmitter';
import config from './config';

// Create a custom Axios instance
const axiosInstance = axios.create({
  baseURL: config.API_URL+'/api',
});

// Add a response interceptor
axiosInstance.interceptors.response.use(
  response => response, // Pass through successful responses
  error => {
    if (error.response) {
      const { status } = error.response;

      switch (status) {
        case 500:
          emitter.emit('showSnackbar', { message: 'Internal Server Error', severity: 'error' });
          break;
        case 403:
          emitter.emit('navigate', '/403');
          break;
        case 401:
          emitter.emit('navigate', '/login');
          break;
        default:
          emitter.emit('showSnackbar', { message: 'An unexpected error occurred', severity: 'warning' });
      }
    } else if (error.request) {
      // The request was made but no response was received
      emitter.emit('showSnackbar', { message: 'No response from server', severity: 'warning' });
    } else {
      // Something happened in setting up the request
      emitter.emit('showSnackbar', { message: 'Error in setting up request', severity: 'warning' });
    }

    console.error('Axios interceptor error handler:', error); // Debugging
    return Promise.reject(error);
  }
);

export default axiosInstance;