import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import { 
  List, 
  ListItem, 
  ListItemText, 
  ListItemButton,
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle,
  IconButton,
  Tooltip,
  Chip,
  Typography
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { useNavigate } from 'react-router-dom';
import config from '../config';
import { Button as PerformButton } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import axiosInstance from '../axiosInstance';

const TrainingsList = () => {
  const [trainings, setTrainings] = useState([]);
  const [deleteConfirm, setDeleteConfirm] = useState({ open: false, trainingId: null });
  const navigate = useNavigate();

  useEffect(() => {
    fetchTrainings();
  }, []);

  const handlePerformTraining = (trainingId) => {
    navigate(`/perform-training/${trainingId}`);
  };

  const fetchTrainings = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axiosInstance.get(`/trainings`, {
        headers: { 'Authorization': token }
      });
      const sortedTrainings = response.data.sort((a, b) => {
        if (a.isFavorite === b.isFavorite) {
          return new Date(b.date) - new Date(a.date); // Sort by date if favorite status is the same
        }
        return b.isFavorite ? 1 : -1; // Favorites first
      });
      setTrainings(sortedTrainings);
    } catch (error) {
      console.error('Error fetching trainings:', error);
    }
  };

  const handleDelete = async () => {
    if (deleteConfirm.trainingId) {
      try {
        const token = localStorage.getItem('token');
        await axios.delete(`${config.API_URL}/api/trainings/${deleteConfirm.trainingId}`, {
          headers: { 'Authorization': token }
        });
        setDeleteConfirm({ open: false, trainingId: null });
        fetchTrainings(); // Refresh the list after deletion
      } catch (error) {
        console.error('Error deleting training:', error);
      }
    }
  };

  const handleCopy = async (training) => {
    try {
      // Fetch all trainings
      const token = localStorage.getItem('token');
      const response = await axios.get(`${config.API_URL}/api/trainings`, {
        headers: { 'Authorization': token }
      });
      const allTrainings = response.data;

      // Extract the base name (without number)
      const baseName = training.name.replace(/ #\d+$/, '');

      // Find all trainings with the same base name and extract their numbers
      const numbers = allTrainings
        .filter(t => t.name.startsWith(baseName))
        .map(t => {
          const match = t.name.match(/ #(\d+)$/);
          return match ? parseInt(match[1]) : 0;
        });

      // Find the maximum number used
      const maxNumber = Math.max(0, ...numbers);

      // Create the new name with the next number
      const newName = `${baseName} #${maxNumber + 1}`;

      const copiedTraining = {
        ...training,
        exercises: training.exercises.map(e => ({...e, sets: e.sets.map(s => ({...s, finish: null, deviation: null}))})),
        isFavorite: false,
        _id: undefined, // Remove the _id so a new one is generated
        date: new Date().toISOString().split('T')[0], // Set to current date
        name: newName,
        start: null
      };

      navigate('/new-training', { state: { copiedTraining } });
    } catch (error) {
      console.error('Error copying training:', error);
      // Handle the error appropriately (e.g., show an error message to the user)
    }
  };

  const handleToggleFavorite = async (training) => {
    try {
      const updatedTraining = { ...training, isFavorite: !training.isFavorite };
      const token = localStorage.getItem('token');
      await axios.put(`${config.API_URL}/api/trainings/${training._id}`, updatedTraining, {
        headers: { 'Authorization': token }
      });
      fetchTrainings(); // Refetch to update the list and sort
    } catch (error) {
      console.error('Error updating favorite status:', error);
    }
  };

  const getTrainingStatus = (training) => {
    if (!training.start) {
      return { label: 'Not performed', color: 'default' };
    }
    const allSetsFinished = training.exercises.every(exercise => 
      exercise.sets.every(set => set.finish)
    );
    if (allSetsFinished) {
      return { label: 'Finished', color: 'success' };
    }
    return { label: 'In progress', color: 'warning' };
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0]; // This will format the date as YYYY-MM-DD
  };

  const SecondaryContent = React.forwardRef((props, ref) => (
    <Typography component="span" variant="body2" ref={ref}>
      {props.children}
    </Typography>
  ));

  return (
    <div>
      <h1>Trainings List</h1>
      <List>
        {trainings.map((training) => {
          const status = getTrainingStatus(training);
          return (
            <ListItem
              key={training._id}
              disablePadding
              secondaryAction={
                <React.Fragment>
                  <Tooltip title="Copy Training">
                    <IconButton 
                      edge="end" 
                      aria-label="copy"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCopy(training);
                      }}
                      style={{ marginRight: '8px' }}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </Tooltip>
                  <PerformButton
                    color="success"
                    startIcon={<PlayArrowIcon />}
                    onClick={() => handlePerformTraining(training._id)}
                  >
                    Run
                  </PerformButton>
                  <IconButton 
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeleteConfirm({ open: true, trainingId: training._id });
                    }}
                    color="error"
                  >
                    <DeleteIcon />
                  </IconButton>
                </React.Fragment>
              }
            >
              <Tooltip title={training.isFavorite ? "Remove from Favorites" : "Add to Favorites"}>
                <IconButton 
                  edge="start" 
                  aria-label="favorite"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleToggleFavorite(training);
                  }}
                  style={{ marginLeft: '8px' }}
                >
                  {training.isFavorite ? <StarIcon color="primary" /> : <StarBorderIcon />}
                </IconButton>
              </Tooltip>
              <ListItemButton onClick={() => navigate(`/edit-training/${training._id}`)}>
                <ListItemText 
                  disableTypography
                  primary={<Typography variant="body1">{training.name}</Typography>}
                  secondary={
                    <Typography variant="body2" component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                      {formatDate(training.date)}
                      <Chip 
                        label={status.label} 
                        color={status.color} 
                        size="small" 
                        sx={{ marginLeft: 1 }}
                      />
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
      <Button onClick={() => navigate('/new-training')} variant="contained" color="primary">
        Create New Training
      </Button>

      <Dialog
        open={deleteConfirm.open}
        onClose={() => setDeleteConfirm({ open: false, trainingId: null })}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this training? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirm({ open: false, trainingId: null })}>Cancel</Button>
          <Button onClick={handleDelete} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TrainingsList;